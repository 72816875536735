<template>
  <div>
    <div id="pozisyon-grid-container">
      <b-form-group>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.pozisyonadd-modal
          class="d-flex align-items-left"
          squared
          variant="outline-primary"
        >
          <feather-icon
            icon="AddIcon"
            class="mr-50"
          />
          <span>Pozisyon Ekle</span>
        </b-button>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        placeholder:'Pozisyon ara'}"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
      @on-row-click="onDataGridItemClicked"
    >
      <div slot="emptystate">
        <center> Pozison kaydı bulunmuyor.</center>
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <template>
              <b-button-group
                class="mt-25"
                size="sm"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  squared
                  class="mr-1"
                  variant="outline-warning"
                  @click="onUpdatePozisyonBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Düzenle</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  squared
                  variant="outline-danger"
                  @click="onDeletePozisyonBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Sil</span>
                </b-button>
              </b-button-group>
            </template>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              1 ila
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> {{ props.total }} kayıt gösteriliyor </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- Pozisyon EKLE MODAL -->
    <b-modal
      id="pozisyonadd-modal"
      cancel-variant="outline-secondary"
      ok-title="Ekle"
      cancel-title="İptal"
      centered
      no-close-on-backdrop
      title="Pozisyon Ekle"
      @ok="onModalSavePozisyon"
      @cancel="onModalCancelled"
    >
      <b-form>
        <b-form-group>
          <label for="staffPositionName">Pozisyon Adı:</label>
          <b-form-input
            id="staffPositionName"
            v-model="newStaffPositionName"
            type="text"
            placeholder="Pozisyon Adı"
            :state="newStaffPositionName.length > 0"
          />
          <b-form-invalid-feedback>
            Pozisyon adı boş bırakılamaz.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- Pozisyon Güncelle  MODAL -->
    <b-modal
      id="pozisyonguncelle-modal"
      cancel-variant="outline-secondary"
      ok-title="Güncelle"
      cancel-title="İptal"
      centered
      no-close-on-backdrop
      title="Pozisyon Güncelle"
      @ok="onModalUpdatePozisyon"
      @cancel="onModalCancelled"
    >
      <b-form>
        <b-form-group hidden>
          <label for="staffPositionId">Identity</label>
          <b-form-input
            id="staffPositionId"
            v-model="newStaffPositionId"
            disabled
            type="text"
          />
        </b-form-group>
        <b-form-group>
          <label for="staffPositionName">Pozisyon Adı:</label>
          <b-form-input
            id="staffPositionName"
            v-model="updStaffPositionName"
            type="text"
            placeholder="Pozisyon Adı"
            :state="updStaffPositionName.length > 0"
          />
          <b-form-invalid-feedback>
            Pozisyon adı boş bırakılamaz.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import * as apiPozisyon from '@/api/tanimlar/personelpozisyon'
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButtonGroup, BButton, BModal, BForm, VBModal, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'PozisyonList',
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButtonGroup,
    BButton,
    BModal,
    BForm,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      newStaffPositionId: -1,
      newStaffPositionName: '',
      updStaffPositionName: '',
      pageLength: 15,
      columns: [
        {
          label: 'Pozisyon Adı',
          field: 'staffPositionName',
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {

  },
  created() {
    apiPozisyon.GetPersonelPozisyonList().then(res => {
      this.rows = res.data.list
    })
  },
  mounted() {
    this.getStaffPositionList()
  },
  methods: {
    getStaffPositionList() {
      apiPozisyon.GetPersonelPozisyonList().then(res => {
        this.rows = res.data.list
      })
    },
    clearModal() {
      this.newStaffPositionName = ''
      this.updStaffPositionName = ''
    },
    onDataGridItemClicked(params) {
      return params
    },
    onModalSavePozisyon() {
      if (this.newStaffPositionName.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kayıt Eklenemedi',
            text: 'Boş kayıt eklenemez.',
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
        return
      }
      const pozisyonItem = {
        staffPositionId: 0,
        staffPositionName: this.newStaffPositionName,
      }
      apiPozisyon.AddPersonelPozisyon(pozisyonItem).then(() => {
        this.getStaffPositionList()
      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Pozisyon Ekle',
          text: 'Yeni kayıt eklendi',
          icon: 'PlusSquareIcon',
          variant: 'success',
        },
      })
      this.clearModal()
    },
    onModalCancelled() {
      this.clearModal()
    },
    onUpdatePozisyonBtnClicked(row) {
      this.newStaffPositionId = row.staffPositionId
      this.updStaffPositionName = row.staffPositionName
      this.$bvModal.show('pozisyonguncelle-modal')
    },
    onDeletePozisyonBtnClicked(row) {
      this.$bvModal.msgBoxConfirm(` '${row.staffPositionName}' tanımı silinecek, onaylıyor musunuz?`, {
        title: 'Uyarı!',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Evet',
        cancelTitle: 'Hayır',
        footerClass: 'p-2',
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
        centered: true,
      })
        .then(value => {
          if (value) {
            apiPozisyon.DeletePersonelPozisyon(row.staffPositionId).then(() => {
              this.getStaffPositionList()
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Kayıt Silindi',
                text: `${row.staffPositionName} kaydı silindi.`,
                icon: 'DeleteIcon',
                variant: 'danger',
              },
            })
          }
        })
      this.clearModal()
    },
    onModalUpdatePozisyon() {
      if (this.updStaffPositionName.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kayıt Güncellenemedi',
            text: 'Boş kayıt güncellenemez.',
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
        return
      }
      const pozisyonItem = {
        staffPositionId: this.newStaffPositionId,
        staffPositionName: this.updStaffPositionName,
      }
      apiPozisyon.UpdatePersonelPozisyon(pozisyonItem).then(() => {
        this.getStaffPositionList()
      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Kayıt Güncellendi',
          text: 'Kayıt başarıyla güncellendi',
          icon: 'EditIcon',
          variant: 'warning',
        },
      })
      this.clearModal()
    },
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
